<template>
  <b-card>
    <validation-observer
      ref="socialForm"
      v-slot="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="LinkIcon"
                    size="18"
                  />
                  <h4 class="mb-0 ml-75">
                    Social Media
                  </h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-form-group
                  label="Phone Number"
                  label-for="account_phone"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="account_phone"
                    rules="required"
                  >
                    <b-form-input
                      v-model="optionsLocal.account_phone"
                      placeholder="08123456789"
                      name="account_phone"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-form-group
                  label="Email"
                  label-for="account_telegram"
                >
                  <b-form-input
                    v-model="optionsLocal.account_telegram"
                    placeholder="Telegram"
                    name="account_telegram"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <b-form-group
                  label="University Email"
                  label-for="account_instagram"
                >
                  <b-form-input
                    v-model="optionsLocal.account_instagram"
                    placeholder="Instagram"
                    name="account_instagram"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-2"
                  :disabled="invalid"
                  @click="submitData()"
                >
                  Save changes
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MechtechFormMixin from '@core/mixins/MechtechFormMixin'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    Ripple,
  },
  mixins: [MechtechFormMixin],
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
    submitData() {
      this.$refs.socialForm.validate().then(success => {
        if (success) {
          console.log(this.optionsLocal)
        }
      })
    },
  },
}
</script>
