<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">General</span>
      </template>

      <user-profile-general
        v-if="options.profile"
        :general-data="options.profile"
      />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LinkIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Social</span>
      </template>

      <user-profile-social
        v-if="options.account"
        :social-data="options.account"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import UserProfileGeneral from "./UserProfileGeneral.vue";
import UserProfileSocial from "./UserProfileSocial.vue";

export default {
  components: {
    UserProfileGeneral,
    UserProfileSocial,
  },
  data() {
    return {
      options: {
        profile: {
          name: "Kevin Test 3",
          date_of_birth: "2001-07-10",
          education_degree: "S1",
          education_major: "Teknik Mesin",
          education_institution_name: "ITS",
          education_start_year: "2019",
        },
        account: {
          account_phone: "08121428132",
          account_telegram: "kevin",
          account_instagram: "kevin",
        }
      },
    };
  },
};
</script>
