<template>
  <b-card>
    <validation-observer
      ref="profileForm"
      v-slot="{invalid}"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-row>
              <b-col cols="12">
                <div class="d-flex align-items-center mb-2">
                  <feather-icon
                    icon="UserIcon"
                    size="18"
                  />
                  <h4 class="mb-0 ml-75">
                    Profile
                  </h4>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Fullname"
                  label-for="fullname"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="fullname"
                    rules="required"
                  >
                    <b-form-input
                      v-model="optionsLocal.name"
                      placeholder="Fullname"
                      name="fullname"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Name"
                  label-for="fullname"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="fullname"
                    rules="required"
                  >
                    <b-form-input
                      v-model="optionsLocal.name"
                      placeholder="Fullname"
                      name="fullname"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Place of Birth"
                  label-for="fullname"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="fullname"
                    rules="required"
                  >
                    <b-form-input
                      v-model="optionsLocal.name"
                      placeholder="Fullname"
                      name="fullname"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Date of Birth"
                  label-for="dob"
                >
                  <b-form-datepicker
                    v-model="optionsLocal.date_of_birth"
                    name="dob"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="University"
                  label-for="universityName"
                >
                  <b-form-input
                    v-model="optionsLocal.education_institution_name"
                    placeholder="University"
                    name="universityName"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="University"
                  label-for="universityName"
                >
                  <b-form-input
                    v-model="optionsLocal.education_institution_name"
                    placeholder="University"
                    name="universityName"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Major"
                  label-for="major"
                >
                  <b-form-input
                    v-model="optionsLocal.education_major"
                    placeholder="Mechanical Engineering"
                    name="major"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Current Semester"
                  label-for="major"
                >
                  <b-form-input
                    v-model="optionsLocal.education_major"
                    placeholder="Mechanical Engineering"
                    name="major"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Entry Year"
                  label-for="startYear"
                >
                  <b-form-input
                    v-model="optionsLocal.education_start_year"
                    name="startYear"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Graduation Year"
                  label-for="startYear"
                >
                  <b-form-input
                    v-model="optionsLocal.education_start_year"
                    name="startYear"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="mt-2"
                  :disabled="invalid"
                  @click="submitData()"
                >
                  Save changes
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MechtechFormMixin from '@core/mixins/MechtechFormMixin'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [MechtechFormMixin],
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    }
  },
  methods: {
    submitData() {
      this.$refs.profileForm.validate().then(success => {
        if (success) {
          console.log(this.optionsLocal)
        }
      })
    },
  },
}
</script>
